<template>
  <!-- 运维 - 模板 - 模板列表 -->
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">招生工具模板</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">已收藏的模板</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="shell">
            <div class="left">模板类型：</div>
            <div
              class="right"
              v-for="(item, index) in template_type_data"
              :key="index"
            >
              <p
                :class="
                  item.templateTypeId == template_type_select ? 'tsColor1' : ''
                "
                @click="screeningConditions(item)"
              >
                {{ item.typeName }}
              </p>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div
              class="m_list"
              v-for="(item, index) in m_list_data"
              :key="index"
            >
              <div class="m_title">{{ item.templateName }}</div>
              <img class="m_img" :src="item.templateImgUrl" alt="" />
              <div class="m_cz">
                <span @click="visualization_edit(item)">编辑</span>
                <span @click="visualization_offCollection(item)">取消收藏</span>
              </div>
            </div>
            <Empty slot="empty" v-if="m_list_data.length < 1" />
          </div>
        </div>
        <div class="pageNum">
          <el-pagination
            :total="fenYe.total"
            :page-size="fenYe.size"
            :current-page="fenYe.currentPage"
            background
            :page-sizes="[10, 20, 30, 40, 50]"
            layout="total, sizes,prev, pager, next,jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
export default {
  name: "yw_visualization_list",
  components: {
    Empty,
  },
  data() {
    return {
      // 模板类型 - id
      template_type_select: -1,
      // 筛选的数据
      template_type_data: [],
      // 模板列表数据
      m_list_data: [],
      // 分页
      fenYe: {
        currentPage: 1, // 当前页数
        size: 10,
        total: null, // 共多少页
      },
    };
  },
  created() {
    this.get_Template_type();
    this.getListData(this.fenYe.currentPage, this.fenYe.size);
  },
  computed: {},
  methods: {
    // 获取模板类型下拉数据
    get_Template_type() {
      this.$post("/design/run/template/getTemplateTypeList").then((res) => {
        const list = [
          {
            deleted: false,
            orderNum: 0,
            templateTypeId: "-1",
            typeName: "全部",
          },
        ];
        for (const key in res.data) {
          list.push({
            deleted: res.data[key].deleted,
            orderNum: res.data[key].orderNum,
            templateTypeId: res.data[key].templateTypeId,
            typeName: res.data[key].typeName,
          });
        }
        this.template_type_data = list;
      });
    },
    // 点击 - 筛选
    screeningConditions(item) {
      this.template_type_select = item.templateTypeId;
      this.getListData(1);
    },
    // 点击 - 编辑
    visualization_edit(item) {
      this.$router.push({
        path: "/visualization",
        query: {
          id: item.templateId,
          compTemplateId: item.compTemplateId,
          type: "jg",
        },
      });
    },
    // 点击 - 收藏/取消收藏
    visualization_offCollection(item, index) {
      const params = {
        templateId: item.templateId,
        favorite: false,
      };
      this.$post("/design/run/comp/template/favoritesTemplate", params)
        .then((res) => {
          // this.m_list_data[index].favorite = !item.favorite;
          this.getListData(1);
          this.$message({
            message: "操作成功",
            type: "success",
          });
        })
        .catch(() => {
          return;
        });
      console.log(item, index);
    },
    // 每页多少条
    handleSizeChange(val) {
      this.fenYe.size = val;
      this.fenYe.currentPage = 1;
      this.getListData(this.fenYe.currentPage, this.fenYe.size);
    },
    // 当前页数
    handleCurrentChange(val) {
      this.fenYe.currentPage = val;
      this.getListData(this.fenYe.currentPage, this.fenYe.size);
    },
    // 获取列表数据
    getListData(page = 1, pagesize = this.fenYe.size) {
      const params = {
        pageNum: page,
        pageSize: pagesize,
      };
      if (this.template_type_select != -1) {
        params.templateTypeId = this.template_type_select;
      }
      this.$post("design/run/comp/template/getFavoritesTemplate", params)
        .then((res) => {
          this.m_list_data = res.data.list;
          this.fenYe.total = res.data.total;
        })
        .catch(() => {
          return;
        });
    },
  },
  watch: {},
};
</script>
<style lang="less">
.tsColor1 {
  color: #409eff !important;
}
.searchbox {
  padding: 0;
}
.framePage-body {
  .shell {
    overflow: hidden;
    .left {
      width: 80px;
      float: left;
      font-size: 14px;
      line-height: 30px;
      text-align: right;
      padding: 0 5px;
    }
    .right {
      float: left;
      font-size: 14px;
      line-height: 30px;
      padding: 0 10px;
      cursor: pointer;
    }
  }
  .m_list {
    width: 183px;
    height: 340px;
    float: left;
    position: relative;
    margin-right: 20px;
    margin-top: 30px;
    margin-bottom: 20px;
    transition: all 0.5s;
    &:hover {
      transform: scale(1.03);
    }
    .m_title {
      text-align: center;
      font-size: 12px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      position: absolute;
      top: -24px;
      left: 0;
      z-index: 2;
    }
    .m_img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 4px;
      cursor: pointer;
    }

    .m_cz {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background: rgba(0, 0, 0, 0.6);
      border-radius: 0 0 4px 4px;
      span {
        width: 50%;
        text-align: center;
        line-height: 26px;
        font-size: 12px;
        cursor: pointer;
      }
      span:first-child {
        color: #409eff;
      }
      span:last-child {
        color: #f56c6c;
      }
    }
  }
}
</style>